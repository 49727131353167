import React, { FC } from "react";
import styles from "./MainPage.module.scss";

import MainAppNavbar from "containers/AppNavbar/MainAppNavbar/MainAppNavbar";
import MainAppCarousel from "containers/AppCarousel/MainAppCarousel/MainAppCarousel";
import AboutAppArticle from "containers/AppArticle/AboutAppArticle/AboutAppArticle";
import Contacts from "containers/Sections/Contacts/Contacts";
import Footer from "containers/Sections/Footer/Footer";

interface MainPageProps {}

const MainPage: FC<MainPageProps> = () => {
  return (
    <>
      <header className="sticky-top border-bottom bg-light">
        <div className=" container">
          <MainAppNavbar />
        </div>
      </header>
      <main className={styles.MainPage + " container"}>
        <section className="mt-4">
          <MainAppCarousel />
        </section>
        <section id="about" className="mt-4">
          <AboutAppArticle />
        </section>
        <section id="contacts" className="mt-4">
          <Contacts />
        </section>
      </main>
      <footer className={styles.Footer}>
        <Footer />
      </footer>
    </>
  );
};

export default MainPage;
