import React, { FC } from "react";
import styles from "./AppCarousel.module.scss";

import classNames from "classnames";
import Carousel from "react-bootstrap/Carousel";

interface AppCarouselProps {
  items: {
    title: string;
    description: string;
    imgSrc: string;
  }[];
}

const AppCarousel: FC<AppCarouselProps> = (props) => {
  const carouselCaptionClasses = classNames(styles.CarouselCaption);
  return (
    <Carousel className={styles.AppCarousel}>
      {props.items.map((item) => (
        <Carousel.Item key={item.title}>
          <img
            className="d-block w-100 fit-cover"
            height="500"
            src={item.imgSrc}
            alt={item.title}
          />
          <Carousel.Caption className={carouselCaptionClasses}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default AppCarousel;
