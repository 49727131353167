import React, { FC } from "react";
import styles from "./AppNavbar.module.scss";

import classNames from "classnames";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

interface AppNavbarProps {
  className: string;
  logo: string;
  title: string;
  menu: { title: string; href: string }[];
}

const AppNavbar: FC<AppNavbarProps> = (props) => {
  const classes = classNames(
    styles.AppNavbar,
    "d-flex flex-wrap justify-content-center justify-content-md-between",
    props.className
  );

  return (
    <Navbar className={classes}>
      <Navbar.Brand href="/">
        {/* <img alt="logo" src={props.logo} height="30" /> */}
        {props.title}
      </Navbar.Brand>
      <Nav>
        {props.menu.map((item) => (
          <Nav.Link key={item.title} href={item.href}>
            {item.title}
          </Nav.Link>
        ))}
      </Nav>
    </Navbar>
  );
};

export default AppNavbar;
