import React, { FC } from "react";
import styles from "./AppArticlCardInCard.module.scss";
import { AppArticleProps } from "../AppArticleProps";

import CardInCard from "components/CardInCard/CardInCard";

export interface AppArticlCardInCardProps extends AppArticleProps {
  backgroundColor?: string;
}

const AppArticlCardInCard: FC<AppArticlCardInCardProps> = (props) => {
  const cardStyles = {
    background: props.backgroundColor || "#1c2c40",
  };
  const title = <h2 className={styles.AppArticlTitle}>{props.title.value}</h2>;
  const content = (
    <div className={styles.AppArticlContent}>
      {props.paragraphs.map((paragraph, index) => (
        <p key={index}>{paragraph.value}</p>
      ))}
    </div>
  );
  const cardInCardProps = { cardStyles, title, content };
  return (
    <article className={styles.AppArticl}>
      <CardInCard {...cardInCardProps} />
    </article>
  );
};

export default AppArticlCardInCard;
