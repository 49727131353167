import React, { FC } from "react";
import styles from "./MainAppNavbar.module.scss";

import logo from "./logo.svg";
import AppNavbar from "components/AppNavbar/AppNavbar";

interface MainAppNavbarProps {}

const MainAppNavbar: FC<MainAppNavbarProps> = () => {
  const title = "ООО «ПрофИТ Софт»";
  const menu = [
    // { title: "Главная", href: "/" },
    { title: "О компании", href: "#about" },
    { title: "Контакты", href: "#contacts" },
  ];
  return (
    <AppNavbar
      className={styles.MainAppNavbar}
      logo={logo}
      title={title}
      menu={menu}
    />
  );
};

export default MainAppNavbar;
