import React, { FC } from "react";
import styles from "./MainAppCarousel.module.scss";

import AppCarousel from "components/AppCarousel/AppCarousel";

interface MainAppCarouselProps {}

const MainAppCarousel: FC<MainAppCarouselProps> = () => (
  <div className={styles.MainAppCarousel}>
    <AppCarousel
      items={[
        {
          title: "Разработка программного обеспечения",
          description:
            "Разработка программного обеспечения – это процесс разработки, уточнения, проектирования, программирования, документирования, тестирования и исправления ошибок, связанных с созданием и поддержкой приложений, баз данных или других программных компонентов.",
          imgSrc: "img/main/carousel-3.jpg",
        },
        {
          title: "Автоматизация и внедрение",
          description:
            "Адаптация, изменение и расширение функциональности программного обеспечения под требования управленческих и учетных задач",
          imgSrc: "img/main/carousel-2.jpg",
        },
        {
          title: "Сопровождение",
          description:
            "Обеспечение функционирования, обновление и восстановление работоспособности программ и баз данных в программно-аппаратной среде пользователя",
          imgSrc: "img/main/carousel-1.jpg",
        },
      ]}
    />
  </div>
);

export default MainAppCarousel;
