import React from "react";
import "./App.scss";

import MainPage from "pages/MainPage/MainPage";

function App() {
  return <MainPage />;
}

export default App;
