import React, { FC } from "react";
import styles from "./Footer.module.scss";

export interface FooterProps {}

const Footer: FC<FooterProps> = (props) => (
  <div
    className={
      styles.Footer +
      " h-100 p-5 text-muted bg-dark d-flex align-items-end justify-content-center"
    }
  >
    ООО «ПрофИТ Софт», {new Date().getFullYear()}
  </div>
);
export default Footer;
