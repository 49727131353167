import React, { FC } from "react";
// import styles from "./AboutAppArticle.module.scss";

import AppArticlCardInCard, {
  AppArticlCardInCardProps,
} from "components/AppArticle/CardInCard/AppArticlCardInCard";

interface AboutAppArticleProps {}

const AboutAppArticle: FC<AboutAppArticleProps> = () => {
  const props: AppArticlCardInCardProps = {
    title: {
      value: "О компании",
    },
    paragraphs: [
      {
        value:
          "Настоящий сайт является официальным сайтом Общества с ограниченной ответственностью «ПрофИТ Софт».",
      },
      {
        value: "ООО «ПрофИТ Софт» образовано в 2005 г.",
      },
      {
        value:
          "Наша компания осуществляет деятельность в области информационных технологий, включена в Реестр аккредитованных организаций, осуществляющих деятельность в области информационных технологий (номер в реестре 653, дата решения об аккредитации 24.12.2010).",
      },
      {
        value:
          "Основной вид деятельности компании: разработка, модификация, адаптация и сопровождение программного обеспечения, баз данных и информационных систем на платформе «1С:Предприятие» (ОКВЭД 62.01 – «Разработка компьютерного программного обеспечения», 63.11.1 – «Деятельность по созданию и использованию баз данных и информационных ресурсов»).",
      },
      {
        value:
          "ООО «ПрофИТ Софт» является официальным партнером фирмы «1С» в статусе «1С: Франчайзи». В нашей компании работают сертифицированные фирмой «1С» специалисты, которые постоянно совершенствуют свои знания и навыки, что подтверждает высокий уровень профессионализма наших сотрудников.",
      },
    ],
  };
  return (
    // <div className={styles.AboutAppArticle}>
    <AppArticlCardInCard {...props} />
    // </div>
  );
};

export default AboutAppArticle;
