import React, { FC } from "react";
import styles from "./CardInCard.module.scss";

export interface CardInCardProps {
  title?: any;
  content: any;
  cardStyles?: any;
}

const CardInCard: FC<CardInCardProps> = (props) => {
  const { title, content, cardStyles } = props;
  return (
    <div className={styles.CardInCard} style={cardStyles}>
      <div className={styles.CardInCardTitle}>{title}</div>
      <div className={styles.CardInCardContent}>{content}</div>
    </div>
  );
};

export default CardInCard;
